
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,500;0,700;1,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/**
* Add css variables here as well for light mode in addition to tailwind.config to avoid FOUC
*/
:root {
  /* background */
  --color: #c000ff;
    --d9d9d9: #d9d9d9;
    --bl: #333;
    --8a24eb: #8a24eb;
    --bla: #000;
    --dbdbdb: #dbdbdb;
    --faf4f4: #faf4f4;
    --f5f5f5: whitesmoke;
  --cal-bg-emphasis: #e5e7eb;
  --cal-bg: white;
  --cal-bg-subtle: #f3f4f6;
  --cal-bg-muted: #f9fafb;
  --cal-bg-inverted: #111827;

  /* background -> components*/
  --cal-bg-info: #dee9fc;
  --cal-bg-success: #e2fbe8;
  --cal-bg-attention: #fceed8;
  --cal-bg-error: #f9e3e2;
  --cal-bg-dark-error: #752522;

  /* Borders */
  --cal-border-emphasis: #9ca3af;
  --cal-border: #d1d5db;
  --cal-border-subtle: #e5e7eb;
  --cal-border-booker: #e5e7eb;
  --cal-border-muted: #f3f4f6;
  --cal-border-error: #aa2e26;

  /* Content/Text */
  --cal-text-emphasis: #111827;
  --cal-text: #374151;
  --cal-text-subtle: #6b7280;
  --cal-text-muted: #9ca3af;
  --cal-text-inverted: white;

  /* Content/Text -> components */
  --cal-text-info: #253985;
  --cal-text-success: #285231;
  --cal-text-attention: #73321b;
  --cal-text-error: #752522;

  /* Brand shinanigans
      -> These will be computed for the users theme at runtime.
    */
  --cal-brand: #111827;
  --cal-brand-emphasis: #101010;
  --cal-brand-text: white;
}
.dark {
  /* background */

  --cal-bg-emphasis: #2b2b2b;
  --cal-bg: #101010;
  --cal-bg-subtle: #2b2b2b;
  --cal-bg-muted: #1c1c1c;
  --cal-bg-inverted: #f3f4f6;

  /* background -> components*/
  --cal-bg-info: #263fa9;
  --cal-bg-success: #306339;
  --cal-bg-attention: #8e3b1f;
  --cal-bg-error: #8c2822;
  --cal-bg-dark-error: #752522;

  /* Borders */
  --cal-border-emphasis: #575757;
  --cal-border: #444444;
  --cal-border-subtle: #2b2b2b;
  --cal-border-booker: #2b2b2b;
  --cal-border-muted: #1c1c1c;
  --cal-border-error: #aa2e26;

  /* Content/Text */
  --cal-text-emphasis: #f3f4f6;
  --cal-text: #d6d6d6;
  --cal-text-subtle: #a5a5a5;
  --cal-text-muted: #575757;
  --cal-text-inverted: #101010;

  /* Content/Text -> components */
  --cal-text-info: #dee9fc;
  --cal-text-success: #e2fbe8;
  --cal-text-attention: #fceed8;
  --cal-text-error: #f9e3e2;

  /* Brand shenanigans
      -> These will be computed for the users theme at runtime.
    */
  --cal-brand: white;
  --cal-brand-emphasis: #e1e1e1;
  --cal-brand-text: black;
}

@layer base {
  * {
    @apply border-default 
  }
}

::-moz-selection {
  color: var(--cal-brand-text);
  background: var(--cal-brand);
}

::selection {
  color: var(--cal-brand-text);
  background: var(--cal-brand);
}

html {
  scrollbar-gutter: stable;
}

body  {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

/*
  Desktop App specific CSS
    https://docs.todesktop.com/
*/

/* disable user selection on buttons, links and images on desktop app */
html.todesktop button,
html.todesktop a,
html.todesktop img,
html.todesktop header {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default !important;
}

html.todesktop,
html.todesktop div {
  cursor: default !important;
}

/* make header draggable on desktop app */
html.todesktop header {
  -webkit-app-region: drag;
}
html.todesktop header button {
  -webkit-app-region: no-drag;
}

html.todesktop .logo {
  display: none;
}

.desktop-only {
  display: none;
}

html.todesktop .desktop-only {
  display: block;
}

html.todesktop .desktop-hidden {
  display: none;
}

html.todesktop header {
  margin-top: -14px;
}

html.todesktop header nav {
  margin-top: 8px;
}

html.todesktop aside {
  margin: 0px -6px;
}

html.todesktop-platform-darwin .desktop-transparent {
  background: transparent !important;
  border: none !important;
}

html.todesktop-platform-darwin.dark main.bg-default {
  background: rgba(0, 0, 0, 0.6) !important;
}

html.todesktop-platform-darwin.light main.bg-default {
  background: rgba(255, 255, 255, 0.8) !important;
}

/*
html.todesktop aside a {
  height: 28px;
  padding: 0px 8px;
  font-size: 12px;
  color: #383438 !important
}

html.todesktop nav a:hover{
  background-color: inherit !important
}

html.todesktop nav a[aria-current="page"]{
  background: rgba(0, 0, 0, 0.1) !important;
}

html.todesktop nav a svg{
  color: #0272F7 !important
} */

/*
  Adds Utility to hide scrollbar to tailwind
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/*
 * Override the default tailwindcss-forms styling (default is: 'colors.blue.600')
 * @see: https://github.com/tailwindlabs/tailwindcss-forms/issues/14#issuecomment-1005376006
 */
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="checkbox"]:focus,
[type="radio"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: var(--brand-color);
  border-color: var(--brand-color);
}

@layer components {
  .scroll-bar {
    @apply scrollbar-thin scrollbar-thumb-rounded-md dark:scrollbar-thumb-darkgray-300 scrollbar-thumb-gray-300 scrollbar-track-transparent;
  }
}

/* TODO: avoid global specific css */
/* button[role="switch"][data-state="checked"] span {
  transform: translateX(16px);
} */

@layer components {
  /* slider */
  .slider {
    @apply relative flex h-4 w-40 select-none items-center;
  }

  .slider > .slider-track {
    @apply relative h-1 flex-grow rounded-md bg-gray-400;
  }

  .slider .slider-range {
    @apply absolute h-full rounded-full bg-gray-700;
  }

  .slider .slider-thumb {
    @apply block h-3 w-3 cursor-pointer rounded-full bg-gray-700 transition-all;
  }

  .slider .slider-thumb:hover {
    @apply bg-gray-600;
  }

  .slider .slider-thumb:focus {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.2);
  } 
}

/* hide chat bubble on mobile */
@media only screen and (max-width: 768px) {
  /* Intercom FAB*/
  #launcher {
    display: none !important;
  }

  /* Zendesk FAB*/
  div[role="presentation"] > iframe {
    display: none !important;
  }

  /* Helpscout FAB*/
  .BeaconFabButtonFrame {
    margin-left: -30px;
    left: 50%;
    bottom: 28px !important;
    z-index: 1058 !important;
  }
}

/* TODO: implement styling for react-multi-email */

/* !important to override react-dates */
.DateRangePickerInput__withBorder {
  border: 0 !important;
}
.DateInput_input {
  border: 1px solid #d1d5db !important;
  border-radius: 2px !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  color: #000;
  padding: 11px ​11px 9px !important;
  line-height: 16px !important;
}

.DateInput_input__focused {
  border: 2px solid #000 !important;
  border-radius: 2px !important;
  box-shadow: none !important;
  padding: 10px ​10px 9px !important;
}

.DateRangePickerInput_arrow {
  padding: 0px 10px;
}

.loader {
  display: block;
  width: 30px;
  height: 30px;
  margin: 60px auto;
  position: relative;
  border-width: 4px;
  border-style: solid;
  animation: loader 2s infinite ease;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  animation: loader-inner 2s infinite ease-in;
}

.no-ring-inset {
  --tw-ring-inset: unset;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.text-inverted-important {
  color: white !important;
}

@layer utilities {
  .transition-max-width {
    -webkit-transition-property: max-width;
    transition-property: max-width;
  }
}

#timeZone input:focus {
  box-shadow: none;
}

/* react-date-picker forces a border upon us, cast it away */
.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__inputGroup__input {
  padding-top: 0;
  padding-bottom: 0;
}

/* animations */
.slideInBottom {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInBottom;
}

@keyframes slideInBottom {
  from {
    opacity: 0;
    transform: translateY(30%);
    pointer-events: none;
  }
  to {
    opacity: 1;
    pointer-events: auto;
  }
}

/* animations */
.slideInTop {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
}

@keyframes slideInTop {
  from {
    opacity: 0;
    transform: translateY(-20%);
    pointer-events: none;
  }
  to {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0%);
  }
}

.fadeIn {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fadeIn;
  animation-timing-function: ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/**
 * Makes sure h-screen works on mobile Safari. By default h-screen
 * does not take into account the height of the address bar, causing
 * weird behaviour when scrolling — sometimes the height will be correct
 * and sometimes it won't, depending on whether the address bar is
 * in 'collapsed' state or not.
 * @see: https://benborgers.com/posts/tailwind-h-screen
 */
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.react-tel-input .country-list .country:hover,
.react-tel-input .country-list .country.highlight {
  @apply !bg-emphasis;
}

.react-tel-input .flag-dropdown .selected-flag,
.react-tel-input .flag-dropdown.open .selected-flag {
  @apply !bg-default;
}

.react-tel-input .flag-dropdown {
  @apply !border-r-default left-0.5 !border-y-0 !border-l-0;
}
@layer components{
  .w-block {
    @apply block;
  }
  .w-button {
    @apply text-white leading-[inherit] cursor-pointer no-underline inline-block px-[15px] py-[9px] rounded-none border-0;
  }
  input.w-button {
    -webkit-appearance: button;
  }

  
.nav_logo {
  @apply flex-row justify-between items-center flex mb-10;
}
.main_cont {
  @apply min-h-screen  px-[60px];
   /* pt-5 */
}
.nav_cont {
  @apply max-w-[30%] items-center flex;
}
.btn_cont {
  @apply gap-x-5 gap-y-5 flex;
}
.btn_main {
  @apply bg-[color:var(--color)] text-sm px-[15px] py-2.5 rounded-[20px];
  font-family: Nunito, sans-serif;
}

.btn_main.brand_btn {
  /* @apply hidden; */
}
.btn_main.brand_btn.load_more {
  @apply inline-block mx-auto;
}
.vide_cont {
  @apply justify-center items-center flex;
}
.vide_img {
  @apply max-w-[60%];
}
.vide_h1 {
  @apply text-white text-5xl font-bold leading-[60px] mt-5 mb-2.5 pl-5;
  font-family: Nunito, sans-serif;
}
.desc_cont {
  @apply w-2/5 bg-[color:var(--d9d9d9)] p-5 rounded-[25px];
}
.fanclub_descr_cont {
  @apply gap-x-[30px] gap-y-[30px] justify-between items-stretch flex mt-[140px] mb-[100px];
}
.image {
  @apply h-4/5;
}
.about_desc_cont {
  @apply flex-col justify-center items-stretch flex mr-5 pr-2.5;
}
.about_sec {
  @apply text-[color:var(--bl)] justify-between items-center flex ml-10 pb-10;
}
.head_h2 {
  @apply text-[color:var(--bl)] text-2xl leading-10 my-2.5;
  font-family: Nunito, sans-serif;
}
.head_h2.clr {
  @apply text-[color:var(--8a24eb)] text-[2rem] leading-10;
  font-family: Nunito, sans-serif;
}
.p3 {
  @apply text-[color:var(--8a24eb)] text-left text-[2rem] mb-0;
  font-family: Nunito, sans-serif;
}
.abt_card {
  @apply gap-x-5 gap-y-5 justify-between items-center flex;
}
.footer_cont {
  @apply   flex-row justify-between items-center flex px-5 border-solid ;
}
.footer_right {
  @apply gap-x-2.5 gap-y-2.5 flex-col justify-center items-end flex mr-2.5 pt-5 pb-2.5 px-2.5;
}
.p_link_upper,
.p_link_lower {
  @apply items-end flex;
}
.footer_center {
  @apply gap-x-5 gap-y-5 justify-around items-center flex;
}
.para {
  @apply text-base leading-[25px] mb-0 text-black; 
  font-family: Nunito, sans-serif;
}
.right_img {
  @apply max-w-[70%] mr-[-60px] rounded-[30px];
}
.p_foot {
  @apply text-[color:var(--dbdbdb)] text-base mb-0 xl:text-2xl;
  font-family: Nunito, sans-serif;
}
.p_img {
  @apply pl-2.5;
}
.para_best {
  @apply text-[color:var(--faf4f4)] text-center text-2xl leading-10 mb-2.5 px-2.5;
}
.best_heading {
  @apply text-[color:var(--faf4f4)] text-center text-5xl leading-[60px] mt-5 mb-2.5 p-2.5;
}
.card_fan {
  @apply w-[30%] border border-[color:var(--f5f5f5)] block pl-5 pr-2.5 py-2.5 rounded-[20px] border-solid;
}
.card_cont {
  @apply gap-x-10 gap-y-10 grid-rows-[auto_auto] grid-cols-[1fr_1fr] auto-cols-[1fr] justify-start flex ml-[60px] mt-10;
  flex-flow: wrap;
  align-content: stretch;
}
.card_h1 {
  @apply text-[color:var(--f5f5f5)] text-center text-[0.8rem] font-bold leading-[25px] mt-2.5 mb-0;
  font-family: Nunito, sans-serif;
}
.card_p {
  @apply text-[color:var(--f5f5f5)] text-left text-[0.8rem] font-normal leading-[25px] mb-0 py-2.5;
  font-family: Nunito, sans-serif;
}
.creator_cont {
  @apply mt-[100px] pb-10;
}
.img_2 {
  @apply rounded-[50px_0_0_50px];
}
.creator_section {
  @apply mt-[140px];
}
.filter_cont {
  @apply w-full justify-between items-center flex;
}
.tags_cont {
  @apply flex-col flex;
}
.profile_pic_cont {
  @apply max-w-[50%] rounded-[50%];
}
.card_fan_profile {
  @apply w-1/5 border border-[color:var(--f5f5f5)] flex-col items-center flex pl-5 pr-2.5 py-2.5 pt-5 rounded-[20px] border-solid;
}
.profile_img {
  @apply inline-block rounded-[50%];
}
.filter_drop_cont {
  @apply items-center flex;
}
.sort_by {
  @apply text-[color:var(--faf4f4)] text-2xl mb-0;
}
.load_more_cont {
  @apply flex mt-10;
}
.dropdown-toggle {
  @apply text-[color:var(--faf4f4)];
}
@media screen and (min-width: 1280px) {
  .card_h1,
  .card_p,
  .dropdown-toggle {
    @apply text-base;
  }
  .txt {
    @apply text-2xl;
  }
}
@media screen and (min-width: 1440px) {
  .btn_main {
    @apply text-lg ;
    /* p-5 */
  }
  .head_h2 {
    @apply text-5xl;
  }
  .head_h2.clr {
    @apply text-[2.5rem] leading-[60px];
  }
  .p3 {
    @apply text-[2.5rem];
  }
  .vide_head {
    @apply text-5xl leading-10;
  }
  .para {
    @apply text-2xl leading-10;
  }
  .card_h1 {
    @apply text-[1.3rem] mt-5 mb-2.5;
  }
  .card_p {
    @apply text-[1.2rem];
  }

  .card_fan_profile {
    @apply w-[30%] flex-col;
  }
}
@media screen and (min-width: 1920px) {
  .btn_main,
  .btn_main.brand_btn.load_more {
    @apply text-[2rem];
  }
  .vide_h1 {
    @apply text-8xl leading-[120px];
  }
  .head_h2 {
    @apply text-8xl leading-[8rem];
  }
  .head_h2.clr {
    @apply text-8xl leading-[7rem];
  }
  .p3 {
    @apply text-8xl;
  }
  .abt_card {
    @apply gap-x-[60px] gap-y-[60px] mb-10;
  }
  .para {
    @apply text-[2rem] leading-[50px];
  }
  .p_foot {
    @apply text-[2rem] leading-10;
  }
  .para_best {
    @apply text-[2rem] leading-[60px];
  }
  .best_heading {
    @apply mb-5;
  }
  .card_h1 {
    @apply text-[2.5rem] leading-[60px];
  }
  .card_p {
    @apply text-[2rem] leading-[50px];
  }
  .creator_section {
    @apply mt-[170px];
  }
} 

@media screen and (max-width: 991px) {
  .about_desc_cont {
    @apply pr-0;
  }
  .about_sec {
    @apply ml-0;
  }
  .head_h2.clr {
    @apply text-2xl leading-[30px];
  }
  .p3 {
    @apply text-3xl;
  }
  .best_heading {
    @apply text-[2rem];
  }
  .card_fan {
    @apply w-[30%];
  }
  .card_cont {
    @apply gap-x-[25px] gap-y-[25px];
  }
  .card_fan_profile {
    @apply w-[30%];
  }
}  @media screen and (max-width: 640px) {   .filter_cont {
  @apply flex-col justify-center items-center;
}
}
@media screen and (max-width: 767px) {
  .main_cont {
    @apply px-5;
  }
  .btn_main {
    @apply px-2.5;
  }
  .vide_h1 {
    @apply text-[2rem] leading-10 mr-5;
  }
  .desc_cont {
    @apply w-[90%];
  }
  .fanclub_descr_cont {
    @apply flex-col items-center;
  }
  .about_sec {
    @apply ml-0;
  }
  .head_h2 {
    @apply text-3xl;
  }
  .right_img {
    @apply -mr-5;
  }
  .para_best {
    @apply text-xl leading-[25px];
  }
  .best_heading {
    @apply text-3xl leading-[44px];
  }
  .card_cont {
    @apply ml-5;
  }
  .card_h1 {
    @apply text-base leading-[25px];
  }
  .card_p {
    @apply text-[0.8rem] leading-[25px];
  }
  .creator_cont {
    @apply mt-[60px];
  }
  .card_fan_profile {
    @apply w-[30%];
  }
}
@media screen and (max-width: 479px) {
  .btn_cont {
    @apply max-w-[50%] gap-x-[5px] gap-y-[5px] flex-row items-center;
  }
  .btn_main {
    @apply text-xs;
  }
  .btn_main.brand_btn {
    /* @apply hidden; */
  }
  .vide_cont {
    @apply flex-col;
  }
  .vide_img {
    @apply max-w-full;
  }
  .vide_h1 {
    @apply text-left mr-0 pl-0;
  }
  .desc_cont {
    @apply w-[90%];
  }
  .fanclub_descr_cont {
    @apply flex-col items-center;
  }
  .about_desc_cont {
    @apply w-full flex-col justify-center items-stretch mr-0 mt-5;
  }
  .about_sec {
    @apply flex-col-reverse items-center;
  }
  .head_h2.clr {
    @apply text-3xl leading-10;
  }
  .abt_card {
    @apply gap-x-[15px] gap-y-[15px] justify-center items-center;
  }
  .footer_cont {
    @apply flex-col;
  }
  .footer_right {
    @apply hidden;
  }
  .footer_center {
    @apply mb-10 px-2.5;
    flex-flow: wrap;
  }
  .right_img {
    @apply max-w-full mr-0;
  }
  .para_best {
    @apply text-left leading-[27px];
  }
  .best_heading {
    @apply text-left text-2xl leading-[30px];
  }
  .card_fan {
    @apply w-[90%] mx-auto;
  }
  .card_cont {
    @apply flex-col;
  }
  .filter_cont {
    @apply flex-col justify-center items-center;
  }
  .card_fan_profile {
    @apply w-[90%] mx-auto;
  }
}
}